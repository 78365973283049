<template>
  <div class="warehouses__content">
    <div
      class="h3"
      v-tv:product="'availabilities-per-warehouse'"
    />
    <form @submit.prevent="loadPlants">
      <div class="form pure-form">
        <div class="pure-u-1">
          <div class="warehouses__content__form">
            <div class="pure-u-1 pure-u-md-1-2">
              <div class="warehouses__content__form__quantity">
                <div class="label-small">
                  <label
                    for="quantity"
                    v-tv:product="'quantity'"
                  />
                </div>
                <div>
                  <NumericInput
                    id="quantity"
                    name="quantity"
                    v-model="chosenQuantity"
                    :min="minQuantity"
                    :max="maxQuantity"
                  />
                </div>
              </div>
            </div>
            <div class="pure-u-1 pure-u-md-1-2">
              <div class="warehouses__content__form__date">
                <div class="label-small">
                  <label
                    for="warehouses_date"
                    v-tv:product="'availabilityDate'"
                  />
                </div>
                <div class="prod-d-buyarticle__input calendar">
                  <DatePicker
                    v-model="chosenDate"
                    name="availabilityDate"
                    id="warehouses_date"
                    @change="handleDateChange"
                    :min="minAvailabilityDate"
                    :max="maxAvailabilityDate"
                  />
                </div>
              </div>
            </div>
            <div class="pure-u-1 pure-u-md-1">
              <div class="warehouses__content__form__button">
                <kbutton
                  :theme-color="'base'"
                  v-tv:product="'reload'"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
    <hr>
    <div
      class="popup-message custom-scrollbar"
      :class="{ loading: loadWarehouses }"
    >
      <div class="pure-g warehouse-container">
        <div
          class="warehouse__wrapper pure-u-1 pure-u-md-1-3"
          v-for="(house, index) in warehouses"
          :key="index"
        >
          <div
            class="warehouse__wrapper__tables"
            :class="{ 'availabilities-loading': house.availabilitiesLoading }"
          >
            <div
              class="warehouse__wrapper__tables__table"
              :class="{
                chosen: house.alternativePlantGuid === chosenWarehousePlantGuid,
              }"
            >
              <input
                type="radio"
                :id="house.alternativePlantGuid"
                :value="house.alternativePlant"
                :checked="
                  house.alternativePlantGuid === chosenWarehousePlantGuid
                "
                name="radioWarehouse"
                @click="chosenWarehouse = house"
                :disabled="house.availabilitiesLoading || house.displayOnly"
              >
              <label :for="house.alternativePlantGuid">
                {{ $tv(getWarehouseName(house), plantTranslationKey) }}
                <span v-if="house.isDefault">*</span>
              </label>
              <div
                v-if="house.quantity != null"
                class="warehouse__wrapper__tables__table__stock subline"
              >
                <div>{{ $tv("stock", "product") }}:</div>
                <div>{{ house.quantity }}</div>
              </div>
              <ProductStock :stock-schedule-rows="house.stockAvailabilities" />
            </div>
            <div class="loading-container">
              <WaitingIndicator />
            </div>
          </div>
        </div>
      </div>
      <div class="loading-container">
        <AppLoadingIndicator />
      </div>
    </div>
    <div class="warehouses__footer">
      <div class="notice subline">
        <span v-text="'*'" /><span v-tv:product="'defaultWarehouseNotice'" />
      </div>
      <div>
        <kbutton
          class="submit"
          :theme-color="'primary'"
          @click="submit()"
          v-tv="'OK'"
          :disabled="loadWarehouses || !chosenWarehouse"
        />
      </div>
    </div>
  </div>
</template>
<script>
import DatePicker from '~/components/input-components/DatePicker'
import {storeToRefs} from 'pinia'
import {usePandaService} from '@/composables/panda_service'
import {useProductService} from '@/composables/product_service'
import {
  PLANT,
  MIN_MAX_VALUES,
  PROPERTY_SCOPE_NAMES
} from '~/assets/js/constants'
import {getNextDate, getMaxDate} from '~/assets/js/utils'
import ProductStock from '~/components/product-detail/buy-box/ProductStock'
import NumericInput from '~/components/input-components/NumericInput'
import AppLoadingIndicator from '~/components/loading-indicators/app-loading-indicator.vue'
import WaitingIndicator from '~/components/loading-indicators/waiting-indicator.vue'
import {Button} from '@progress/kendo-vue-buttons'
import {useCartStore} from '@/store/cart'
import {usePlantStore} from '@/store/plants'

export default {
  props: {
    product: {
      type: Object,
      default() {
        return null
      }
    },
    date: {
      type: Date,
      default() {
        return new Date()
      }
    },
    quantity: {
      type: Number,
      default() {
        return 1
      }
    },
    warehouse: {
      type: Object,
      default() {
        return null
      }
    },
    reloadWarehouses: {
      type: Boolean,
      default() {
        return false
      }
    }
  },
  components: {
    kbutton: Button,
    NumericInput,
    ProductStock,
    DatePicker,
    AppLoadingIndicator,
    WaitingIndicator
  },
  setup() {
    const {deliveryAddressUuid} = storeToRefs(useCartStore())
    const {allPlants} = storeToRefs(usePlantStore())
    const {getAvailabilitiesBulk} = usePandaService()
    const {getProductBySku, getProductBySkuNew} = useProductService()
    return {
      deliveryAddressUuid,
      fetchedWarehouses: allPlants,
      getAvailabilitiesBulk,
      getProductBySku,
      getProductBySkuNew
    }
  },
  data() {
    return {
      warehouses: [],
      loadWarehouses: false,
      chosenWarehouse: null,
      showDatePicker: false,
      chosenQuantity: 1,
      chosenDate: getNextDate(),
      plantTranslationKey: PLANT.PLANT_TRANSLATION_KEY,
      defaultPlantGuid: PLANT.DEFAULT_PLANT_GUID,
      minAvailabilityDate: getNextDate(),
      maxAvailabilityDate: getMaxDate(),
      minQuantity: MIN_MAX_VALUES.Quantity.Min,
      maxQuantity: MIN_MAX_VALUES.Quantity.Max,
      availabilities: []
    }
  },
  watch: {
    quantity: {
      immediate: true,
      handler(val) {
        this.chosenQuantity = val
      }
    },
    date: {
      immediate: true,
      handler(val) {
        this.chosenDate = val
      }
    },
    warehouse: {
      immediate: true,
      handler(val) {
        this.chosenWarehouse = val
      }
    },
    reloadWarehouses: {
      immediate: true,
      handler(reload) {
        if (reload) {
          this.loadPlants()
        }
      }
    }
  },
  computed: {
    chosenWarehousePlantGuid() {
      return this.chosenWarehouse
        ? this.chosenWarehouse.alternativePlantGuid
        : ''
    }
  },
  methods: {
    async loadPlants() {
      if (this.hasRight(this.USER_RIGHTS_MAPPING.PRODUCT_VIEW_AVAILABILITY)) {
        this.loadWarehouses = true
        this.chosenWarehouse = this.warehouse
        await this.$nextTick()
        if (this.product) {
          let warehouses = this.fetchedWarehouses.filter(
            (p) => p.plant === this.product?.defaultPlantCode
          )
          for (let w of warehouses) {
            if (w.alternativePlant !== 'default-plant') {
              await this.getProductBySkuNew(
                this.product?.sku,
                this.$globalization.getRegion(),
                this.$i18n.locale,
                false,
                w.alternativePlant,
                PROPERTY_SCOPE_NAMES.PLANTS_ONLY_NEW
              ).then((product) => {
                let plant = product.plants.find(
                  (p) => p.key === w.alternativePlant.toString()
                )
                w.existingForProduct = typeof plant !== 'undefined'
              })
            }
          }
          warehouses = warehouses.filter((w) => w.existingForProduct)
          this.warehouses =
            warehouses?.map((w) => ({...w, availabilitiesLoading: true})) ??
            []
          this.loadWarehouses = false
          await this.$nextTick()
          this.loadAvailabilities()
        } else {
          this.warehouses = []
          this.loadWarehouses = false
        }
      }
    },
    async loadAvailabilities() {
      const filter = []
      const basicFilterObject = {
        sku: this.product?.sku,
        quantity: this.chosenQuantity,
        availabilityDate: this.chosenDate
      }
      this.warehouses.forEach((warehouse) => {
        const filterObject = {
          ...basicFilterObject,
          requestId: warehouse?.alternativePlantGuid
        }
        const plant =
          warehouse && !warehouse.isDefault ? warehouse.alternativePlant : null
        if (plant) {
          filterObject.plant = plant
        }
        filter.push(filterObject)
      })

      this.availabilities =
        filter.length > 0
          ? (await this.getAvailabilitiesBulk(filter, true)) ?? []
          : []
      this.warehouses.forEach((warehouse) => {
        let availability = this.availabilities.find(
          (a) => a.requestId === warehouse.alternativePlantGuid
        )
        if (availability) {
          warehouse.stockAvailabilities = availability.stockAvailabilities
          warehouse.quantity = availability.currentStock?.quantity
        }
        warehouse.availabilitiesLoading = false
      })
    },
    handleDateChange(data) {
      this.chosenDate = data.value
    },
    submit() {
      this.$emit('warehouse', {
        warehouse: this.chosenWarehouse,
        availabilityDate: this.chosenDate,
        quantity: this.chosenQuantity,
        availability: this.availabilities.find(
          (a) => a.requestId === this.chosenWarehouse.alternativePlantGuid
        )
      })
      this.$emit('close-pop-up', true)
    },
    getDate(date) {
      return new Date(date)
    },
    getWarehouseName(house) {
      if (house?.isDefault) {
        return this.$tv(
          this.product?.defaultPlantCode,
          PLANT.PLANT_TRANSLATION_KEY
        )
      } else {
        return this.$tv(house?.alternativePlant, PLANT.PLANT_TRANSLATION_KEY)
      }
    }
  },
  watcher: {
    deliveryAddressUuid() {
      this.loadAvailabilities()
    }
  }
}
</script>

<style lang="scss">
.warehouses__content {
  padding: 1.5rem $default-margin-padding-width;

  .warehouse {
    &__wrapper {
      margin-bottom: $default-margin-padding-width;
      flex-direction: column;

      &__tables {
        padding-right: 1em;
        position: relative;

        &__table {
          background-color: $color__content--background__secondary;
          padding: $default-margin-padding-width;
          min-height: 12.8125rem;

          &__stock {
            display: flex;
            justify-content: space-between;
            text-transform: uppercase;
            margin: .8rem 0 .6rem;
          }

          label {
            color: $color__primary;
            display: inline;
          }
        }

        &.availabilities-loading {
          .loading-container {
            display: flex;
          }

          .warehouse__wrapper__tables__table {
            filter: blur(.25rem);
          }
        }

        .stock-schedule__not-available {
          font-size: $base-text-font-size;
          font-weight: $base-text-font-weight-light;
        }
      }
    }
  }

  .popup-message {
    font-size: map-get($base-text-body-medium, font-size );
    line-height: 1rem;
    font-weight: map-get($base-text-body-medium, font-weight );
    max-height: 44rem;
    overflow-y: scroll;
    scrollbar-width: thin;
    scrollbar-color: $color__primary $color__page--background;
    padding-top: .5rem;
    position: relative;

    &.loading {
      .warehouse-container {
        filter: blur(.25rem);
      }

      > .loading-container {
        display: flex;
      }
    }

    .loading-container {
      position: absolute;
      inset: 0;
      z-index: 1;
      display: none;
      justify-content: center;
      align-items: center;
    }

    > .loading-container {
      height: calc(100% - 1em);
      width: calc(100% - 1em);
    }

    .warehouse-container {
      min-height: 14.8125rem;
    }
  }

  .h3 {
    margin-bottom: $default-margin-padding-width;

    @include breakpoint-down($md) {
      width: calc(100% - 1.875rem);
    }
  }

  .chosen {
    background-color: $color__primary;

    label,
    .warehouse__wrapper__tables__table__stock,
    .stock-schedule__not-available {
      color: $color__white;
    }

    .header {
      background-color: $color__page--background;
      color: $color__primary;
    }
  }

  .warehouses__footer {
    display: flex;
    justify-content: space-between;
    margin-top: $default-margin-padding-width;
    padding-right: 1.1875rem;

    .submit {
      padding-left: 5rem;
      padding-right: 5rem;
    }

    .notice {
      margin-top: auto;
      margin-bottom: auto;
    }
  }

  &__form {
    text-transform: uppercase;
    display: flex;

    @include breakpoint-down($md) {
      display: inline;
    }

    &__quantity {
      display: flex;
      justify-content: space-between;
      margin-top: auto;
      margin-bottom: auto;
      margin-right: 1.125rem;

      @include breakpoint-down($md) {
        width: 100%;
        margin-bottom: $default-margin-padding-width;
      }

      .numeric-input-wrapper .k-numerictextbox {
        width: 8.75rem;

        .k-numeric-wrap {
          input {
            text-align: right;
          }
        }
      }

      .label-small {
        margin-top: auto;
        margin-bottom: auto;
        margin-right: $default-margin-padding-width;
      }
    }

    &__date {
      display: flex;
      justify-content: space-between;
      margin-top: auto;
      margin-bottom: auto;
      margin-right: 1.125rem;

      @include breakpoint-down($md) {
        width: 100%;
        margin-left: 0;
        margin-bottom: $default-margin-padding-width;
      }

      .label-small {
        margin-top: auto;
        margin-bottom: auto;
        margin-right: $default-margin-padding-width;
      }

      .k-datepicker {
        width: 8.75rem;

        .k-picker-wrap {
          border-color: $color__input__border__secondary;
          border-radius: 0;

          &:hover {
            border-color: $color__input__border__secondary--hover;
          }
        }

        .k-icon-button {
          margin-left: -1.5rem;
        }
      }

      .k-input {
        text-align: right;
      }
    }

    &__button {
      margin-right: 1.125rem;
      display: flex;
      flex-direction: row-reverse;

      @include breakpoint-down($md) {
        margin-right: 0;
      }

      .k-button {
        padding-left: $default-margin-padding-width;
        padding-right: $default-margin-padding-width;
        min-width: 7.3125rem;
        min-height: 2.5rem;

        @include breakpoint-down($md) {
          margin-right: 0;
          width: 8.75rem;
        }
      }
    }
  }

  hr {
    background-color: $color__content--background__secondary--opacity;
    border: 0;
    height: .1rem;
  }
}
</style>
